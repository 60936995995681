<template>
  <div class="k-layout-basic">

    <k-sidebar :menus="sidebarMenus">
      <template #brand>
        <k-icon icon="x-diamond-fill" />KIRSTY ADMIN
      </template>
    </k-sidebar>

    <section class="k-wraper">
      <k-header />
      <slot></slot>
    </section>

  </div>
</template>

<script>
import { KSidebar, KHeader } from './components';

export default {
  async mounted() {
    const username = await this.$store.dispatch('email/checkLoginState')
    console.log(username);
    if (username) await this.$store.dispatch('email/queryEmailTags', username)
    this.$store.dispatch('posts/query')
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      sidebarMenus: menus,
    }
  },
  components: {
    KSidebar,
    KHeader
  }
}

const menus = [
  {
    type: 'title',
    label: 'BLOG'
  },
  {
    type: 'submenu',
    label: '文章管理',
    icon: 'gear',
    children: [
      {
        type: 'item',
        to: '/blog',
        label: '所有文章',
        icon: 'file-earmark'
      },
      {
        type: 'item',
        to: '/blog/create',
        label: '建立文章',
        icon: 'pencil'
      },
      {
        type: 'item',
        to: '/blog/category',
        label: '管理分類',
        icon: 'diagram-3'
      },
    ]
  },
  {
    type: 'submenu',
    label: 'SEO 設定',
    icon: 'search',
    children: [
      {
        type: 'item',
        to: '/seo',
        label: '管理模板',
        icon: 'files'
      },
      {
        type: 'item',
        to: '/seo/create',
        label: '建立模板',
        icon: 'file-plus'
      },
    ]
  },
  {
    type: 'title',
    label: 'MEDIA'
  },
  {
    type: 'item',
    to: '/media',
    label: '多媒體庫',
    icon: 'play-btn-fill'
  },
  {
    type: 'title',
    label: 'PRODUCT'
  },
  {
    type: 'submenu',
    label: '產品管理',
    icon: 'cart-fill',
    children: [
      {
        type: 'item',
        to: '/product',
        label: '所有產品',
        icon: 'list-ul'
      },
      {
        type: 'item',
        to: '/product/create',
        label: '建立產品',
        icon: 'file-plus'
      },
    ]
  },
]
</script>
