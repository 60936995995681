<script>
export default {
  name: 'KDescriptions',
  props: {
    title: String,
    bordered: Boolean,
    columns: {
      type: [String, Number],
      default: 3
    },
    layout: {
      type: String,
      default: 'horizontal'
    }
  },
  computed: {
    classes() {
      return {
        'layout-vertical': this.layout === 'vertical',
        'bordered': !!this.bordered
      }
    }
  },
  render(h) {
    const renderRow = children => {
      var count = 0
      return children.reduce((acc, item, index) => {
        const columns = Number(this.$props.columns)
        const span = Number(item.componentOptions.propsData.span) || 1

        if (count % columns === 0) acc.push([item])
        else if (span > (columns - count % columns)) {
          acc[acc.length-1].slice(-1)[0].componentOptions.propsData.span = columns
          acc.push([item])
          count = 0
        }
        else acc[acc.length-1].push(item)
        if (index === children.length - 1) item.componentOptions.propsData.span = columns - count % columns || columns
        count += span
        return acc
      },[])
    }
    
    const children = this.$slots.default;
    return (
      <div class="k-descriptions">
        <table class={this.classes}>
          {this.title ? (<h3 class="table-caption">{this.title}:</h3>) : null}
          {renderRow(children, this.$props).map(row => <tr>{row}</tr>)}
        </table>
      </div>
    )
  }
}
</script>
