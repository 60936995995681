import { genBasicModel } from './utils/basicAction'
import { create, update } from './utils/basicService'
import database from '@/utils/database'

const MODEL_NAME = 'product'
const { basicAction, basicMutation } = genBasicModel(
  MODEL_NAME,
  query,
  create(MODEL_NAME, true),
  update(MODEL_NAME))

async function query() {
  const res = await database.table(MODEL_NAME)
    .join('file', 'file_id', 'product', 'product_avatar_id')
    .orderBy('product.product_create_at')
    .get()
  return res
}

export default {
  namespaced: true,
  state: {
    productList: []
  },
  mutations: {
    ...basicMutation,
  },
  actions: {
    ...basicAction,
    async createStructureData({ commit }, payload) {
      const { id, ...formData } = payload
      const response = await database.table('product').where('product_id', '=', id).update(formData)
      if (response.status === 200) commit('updateProduct', { id, ...formData })
      return response
    }
  },
  getters: {
  
  }
}
