const { GoogleSpreadsheet } = require('google-spreadsheet');
const key = require(`./${process.env.VUE_APP_GOOGLE_SHEET_TOKEN_JSON}`)
const doc = new GoogleSpreadsheet(process.env.VUE_APP_GOOGLE_SHEET_ID);

export async function buildSheet({ title, headerValues, headerTexts, rows }) {
  await doc.useServiceAccountAuth({
    client_email: key.client_email,
    private_key: key.private_key
  })
  const sheet = await doc.addSheet({ title, headerValues: headerTexts || headerValues });
  await sheet.addRows(rows)
  return sheet
}
