<script>
import { Chart, registerables } from 'chart.js'
import colorPattern from '@/utils/colorPattern'
Chart.register(...registerables);

export default {
  name: '',
  type: '',
  cuzType: '',
  props: {
    datasets: Array,
    labels: [Array, String],
    options: Object,
    plugins: Array,
    width: String,
    height: String,
  },
  months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  week: ['Mon', 'Tue', 'Wed', 'Thi', 'Fri', 'Sat', 'Sun'],
  colorPattern,
  data() {
    return {
      ctx: undefined,
      chart: undefined,
    }
  },
  mounted() {
    this.ctx = this.$refs.canvas.getContext('2d')
    this.renderChart()
  },
  beforeDestroy () {
    this.destroyChart()
  },
  methods: {
    renderChart() {

    },
    destroyChart() {

    }
  },
  watch: {
    chartConfig () {
      this.updateChart()
    }
  },
  computed: {
    refId () {
      const key = () => Math.random().toString(36).replace('0.', '')
      return '__ref_id__' + (this._uid || key())
    },
    computedLabels() {
      if (this.labels && typeof this.labels !== 'string') return this.labels
      if (!this.datasets || !this.datasets[0] || !this.datasets[0].data) return []

      const emptyLabels = Array(this.datasets[0].data.length).fill('')
      if (this.labels === 'indexes') return emptyLabels.map((e, i) => i + 1)
      if (this.labels === 'months') return emptyLabels.map((e, i) => this.$options.months[i % 12])
      if (this.labels === 'week') return emptyLabels.map((e, i) => this.$options.week[i % 7])
      return emptyLabels
    },
    computedData () {
      return {
        datasets: this.computedDatasets.filter(dataset => !dataset.hide),
        labels: this.computedLabels
      }
    },
    computedDatasets () {
      if (this.chart && this.ctx) {
        const GradientStrok = this.ctx.createLinearGradient(0, this.chart.canvas.height, 0, 0)
        let barPercentage = this.$options.cuzType === 'histogram' ? 1.3 : undefined
          return this.datasets.map(setting => {
          if (setting.fill && !Array.isArray(setting.backgroundColor)) {
            GradientStrok.addColorStop(0.5, `${setting.backgroundColor}1f`);
            GradientStrok.addColorStop(1, `${setting.backgroundColor}99`);
            return { ...setting, barPercentage, backgroundColor: GradientStrok }
          }
          if (setting.colorPattern) {
            const pattern = this.$options.colorPattern[setting.colorPattern] || this.$options.colorPattern['default8']
            return { ...setting, barPercentage, backgroundColor: pattern }
          }
          return { ...setting, barPercentage }
        })
      }
      return this.datasets
    },
    computedOptions () {
      return Object.assign({}, this.options)
    },
    chartConfig () {
      return {
        type: this.$options.type,
        data: this.computedData,
        options: this.computedOptions || this.options,
        plugins: this.plugins
      }
    }
  },
  methods: {
    renderChart () {
      this.destroyChart()
      this.chart = new Chart(
        this.$refs.canvas.getContext('2d'),
        this.chartConfig
      )
    },
    updateChart () {
      Object.assign(this.chart, this.chartConfig)
      this.chart.update()
    },
    destroyChart () {
      if (this.chart) {
        this.chart.destroy()
      }
    }
  },
  render(h) {
    return (
      <div class="chart-wrapper">
        <canvas id={this.refId} style={{ height: this.height ? `${this.height}px` : null }} ref="canvas" />
      </div>
    )
  },
}
</script>
