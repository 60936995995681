import Vue from 'vue'
import Vuex from 'vuex'
import email from './email'
import media from './media'
import posts from './posts'
import product from './product'
import login from './login'
import admin from './admin'
import seo from './seo'
import summary from './summary'
import user from './user'
import orders from './order'
import analytics from './analytics'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    email,
    media,
    posts,
    product,
    login,
    admin,
    seo,
    summary,
    user,
    orders,
    analytics
  }
})
