import { genBasicModel } from './utils/basicAction'
import { create, update } from './utils/basicService'
import database from '@/utils/database'

const MODEL_NAME = 'orders'
const { basicAction, basicMutation } = genBasicModel(
  MODEL_NAME,
  query,
  create(MODEL_NAME, true),
  update(MODEL_NAME))

async function query() {
  const res = await database.table(MODEL_NAME)
    .join('product', 'product_id')
    .join('user', 'user_id')
    .orderBy('orders.orders_create_at')
    .get()
  return res
}

export default {
  namespaced: true,
  state: {
    ordersList: []
  },
  mutations: {
    ...basicMutation,
    batchChangeState(state, { id_array, ...updateData }) {
      let orders_id_list = state.ordersList.map(e => e.key_id)
      id_array.forEach(id => {
        let index = orders_id_list.indexOf(id)
        state.ordersList.splice(index, 1, { ...state.ordersList[index], ...updateData })
      })
    }
  },
  actions: {
    ...basicAction,
    async batchCheckState({ commit }, payload) {
      const { id_array, orders_dealer_id } = payload
      await database.table(MODEL_NAME).where('orders_id', 'in', id_array).update({ orders_state: 'COMPLETE', orders_dealer_id })
      commit('batchChangeState', { id_array, orders_state: 'COMPLETE', orders_dealer_id })
    }
  },
  getters: {
  
  }
}
