<template>
  <span :class="classes" v-click-away="hide"> 
    <a class="k-dropdown-link" ref="dropdown-link" @click="toggleShow">
      <slot></slot>
    </a>
    <transition name="fade">
      <div class="k-dropdown-overlay" v-if="show" :style="{ ...placementStyle[placement] }" @click="hide">
        <slot name="header"><header v-if="header">{{ header }}</header></slot>
        <slot name="overlay"></slot>
        <slot name="footer"><footer v-if="footer">{{ footer }}</footer></slot>
      </div>
    </transition>
    
  </span>
</template>

<script>
export default {
  props: {
    header: String,
    footer: String,
    showOverlay: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'rightBottom'
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggleShow() {
      console.log('click');
      this.show = !this.show
    },
    hide() {
      this.show = false
    }
    
  },
  computed: {
    classes() {
      return {
        'k-dropdown': true
      }
    },
    linkHeight() {
      return this.$refs['dropdown-link'].children[0].offsetHeight;
    },
    placementStyle() {
      return {
        leftBottom: {
          top: `${this.linkHeight+8}px`,
          left: 0,
        },
        rightBottom: {
          top: `${this.linkHeight+8}px`,
          right: 0
        }
      }
    }
  },
  watch: {
    showOverlay() {
      this.show = showOverlay
    }
  }
}
</script>
