<template>
  <k-container class="view-product-container" title="所有產品">
    <k-card class="product-progress">
      <k-progress-circle :value="80" r="70" theme="warning" title="Order" />
      <k-progress-circle :value="72" r="70" theme="info" title="Success" />
      <k-progress-circle :value="9" r="70" theme="error" title="Cancel" />
    </k-card>

    <k-card class="product-list">
      <k-table :items="productList" :fields="fields" hover>
        <template #cell(file_path)="item">
          <img :src="item.value" alt="">
        </template>

        <template #cell(product_status)="item">
          <k-tag :theme="item.item.product_quantity===0 ? 'error' : 'success'">
            {{ item.item.product_quantity===0 ? '補貨中' : '尚有庫存' }}
          </k-tag>
        </template>

        <template #cell(product_states)="item">
          <k-progress-star :value="item.value" showValue></k-progress-star>
        </template>
      </k-table>
    </k-card>
  </k-container>
</template>

<script>
import { KProgress, KProgressCircle, KProgressStar } from '@/components/Progress'
import KTag from '@/components/Tag'
import KCard from '@/components/Card'
import KTable from '@/components/Table'
import { mapState } from 'vuex'

export default {
  name: 'Product',
  mounted() {
    this.$store.dispatch('product/query')
  },
  data() {
    return {
      fields
    }
  },
  computed: {
    ...mapState('product', ['productList'])
  },
  components: {
    KProgressCircle, KCard, KTable, KTag, KProgress, KProgressStar
  }
}

const fields = [
  { key: 'file_path', fieldIndex: 'file_path', label: 'Avatar' },
  { key: 'product_status', fieldIndex: 'product_status', label: '狀態', searchable: true },
  { key: 'product_name', fieldIndex: 'product_name', label: '產品名稱', sorter: (a, b) => ('' + a).localeCompare(b), searchable: true },
  { key: 'product_price', fieldIndex: 'product_price', label: '價格', sorter: (a, b) => b - a },
  { key: 'product_states', fieldIndex: 'product_states', label: '評分', sorter: (a, b) => b - a }
]
</script>
