<template>
  <label :class="{ 'k-select-wraper': true, 'invalid': isInvalid }" for="select1">
    <span class="label" v-if="label"><span class="mark" v-if="required && !hideRequiredMark">＊ </span>{{ label }}</span>
    <input v-if="required" :value="value" @invalid="handleInvalid" required>
    <div :class="classes" v-click-away="unSpread" >
      <div class="k-select-render" @click.stop="toggleSpread">
        <span class="k-select-title">
          {{ value && options.filter(o=>o.value===value)[0].text || placeholder || 'Please select an option' }}
        </span>
      </div>
      <transition name="drop-down">
        <ul v-if="($slots.default || this.options.length) && isSpread" class="option-list" @click.stop="handleSelect">
          <slot></slot>
          <option v-for="item in options" :value="item.value" :key="item.value" >{{ item.text }}</option>
        </ul>
      </transition>
    </div>
    <span v-if="isInvalid" class="extra"><k-icon icon="exclamation-triangle" theme="danger" />本欄位為必填，請選擇選項</span>
  </label>
</template>

<script>
import formMixin from '@/mixin/form'

export default {
  mixins: [formMixin],
  props: {
    value: String,
    theme: String,
    label: String,
    placeholder: String,
    spread: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    block: Boolean,
    required: Boolean,
    hideRequiredMark: Boolean
  },
  mounted() {
    if (this.spread) this.isSpread = this.spread
  },
  data() {
    return {
      isSpread: false
    }
  },
  methods: {
    toggleSpread() {
      this.isSpread = !this.isSpread;
    },
    unSpread() {
      this.isSpread = false
    },
    handleSelect(e) {
      this.handleValid()
      this.$emit('input', e.target.value);
      this.$emit('change', e.target.value);
      this.isSpread = false
    }
  },
  computed: {
    classes() {
      return {
        'k-select': true,
        'isSpread': this.isSpread,
        [`k--${this.theme}`]: !!this.theme,
        'block': !!this.block
      }
    }
  }
}
</script>
