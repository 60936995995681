<template>
  <k-container class="view-user-container">
    <h1>帳號管理</h1>
    <AdminCard :onCopy="onCopy" />
    <k-card>
      <template #header>Accounts</template>
      <k-table :items="items" :fields="fields" hover footerCaption :pagination="items.length > 10 ? pagination : false">
        <!-- <p slot="table-caption">User Data</p> -->
        <template #cell(user_email)="data">
          <span style="margin-right: 8px">{{ data.value }}</span>
          <k-icon v-copy="data.value" v-copy:success="onCopy" icon="clipboard"></k-icon>
        </template>
        <template #cell(user_gender)="data">
          <k-tag :theme="data.value === 'Male' ? 'success' : 'danger'">
            {{ data.value }}
          </k-tag>
        </template>

        <template #cell(detail)="data">
          <k-button theme="info" varient="ghost" @click="changeFocusUser(data.item.user_id)">詳細資料</k-button>
        </template>
      </k-table>
    </k-card>

    <k-modal title="用戶資料" :show.sync="show" :width="600" hideFooter >
      <div v-if="focusUserInfo">
        <h2>{{ focusUserInfo.user_name }}</h2>
        <p>{{ focusUserInfo.user_email }}</p>

        <k-descriptions layout="vertical" bordered>
          <k-descriptions-item label="用戶">{{ focusUserInfo.user_name }}</k-descriptions-item>
          <k-descriptions-item label="年齡">{{ focusUserInfo.user_age }}</k-descriptions-item>
          <k-descriptions-item label="性別">{{ focusUserInfo.user_gender }}</k-descriptions-item>
          <k-descriptions-item label="電子郵件">
            {{ focusUserInfo.user_email }}
            <k-icon v-copy="focusUserInfo.user_email" v-copy:success="onCopy" icon="clipboard"></k-icon>
          </k-descriptions-item>
          <k-descriptions-item label="詳細資訊" span="3">
            Data disk type: MongoDB<br/>
            Database version: 3.4<br/>
            Package: bbs.kirsty.omg<br/>
            Storage space: 10 GB<br/>
            Replication factor: 3<br/>
            Region: East Asia 1
          </k-descriptions-item>
        </k-descriptions>
      </div>
    </k-modal>
  </k-container>
</template>

<script>
import KTable from '@/components/Table'
import KCard from '@/components/Card'
import KTag from '@/components/Tag'
import KButton from '@/components/Button'
import KModal from '@/components/Modal'
import { KDescriptions, KDescriptionsItem } from '@/components/Descriptions'
import AdminCard from './components/AdminCard'

export default {
  name: 'User',
  mounted() {
    this.$store.dispatch('admin/query')
    this.$store.dispatch('user/query')
  },
  data() {
    return {
      fields: [
        { key: 'user_name', fieldIndex: 'user_name', label: '用戶名稱', searchable: true,
          sorter: (a, b) => ('' + a).localeCompare(b)
        },
        { key: 'user_email', fieldIndex: 'user_email', label: '信箱', searchable: true},
        { key: 'user_age', fieldIndex: 'user_age', label: '年齡', sorter: (a, b) => a - b }, 
        { key: 'user_gender', fieldIndex: 'user_gender', label: '性別', filters: true,
          sorter: (a, b) => ('' + a).localeCompare(b),
        }, 
        { key: 'detail', fieldIndex: 'detail', label: '' }, 
      ],
      items: [
        { user_id: 'k001' ,user_name: 'glove', user_email: 'glovecoding@gmail.com', user_age: 24, user_gender: 'Male' },
        { user_id: 'k002' ,user_name: 'paboya', user_email: 'paboya@gmail.com', user_age: 69, user_gender: 'Female' },
        { user_id: 'k003' ,user_name: 'dexter', user_email: 'dexisbitch@gmail.com', user_age: 25, user_gender: 'Male' },
        { user_id: 'k004' ,user_name: 'alexander', user_email: 'alex058188@gmail.com', user_age: 24, user_gender: 'Male' },
        { user_id: 'k005' ,user_name: 'chikenBreast', user_email: 'welovechikenbreast@gmail.com', user_age: 24, user_gender: 'Male' }, 
      ],
      buttonNum: 1,
      show: false,
      pagination: {
        current: 1,
        limit: 5
      },
      focusUserInfo: null
    }
  },
  methods: {
    onCopy() {
      this.$message.success('已複製到剪貼簿')
    },
    changeFocusUser(id) {
      const userIndex = this.items.map(i => i.user_id).indexOf(id)
      this.focusUserInfo = { ...this.items[userIndex] }
      this.show = true
    },
    showModal() {
      this.show = true
    }
  },
  components: {
    AdminCard,
    KTable, KCard, KTag, KButton, KModal, KDescriptions, KDescriptionsItem
  }
}
</script>
