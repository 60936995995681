import { genBasicModel } from './utils/basicAction'
import { query } from './utils/basicService'

const MODEL_NAME = 'user'
const { basicAction, basicMutation } = genBasicModel(MODEL_NAME, query(MODEL_NAME, { order: 'user_create_time' }))

export default {
  namespaced: true,
  state: {
    userList: []
  },
  mutations: {
    ...basicMutation,
  },
  actions: {
    ...basicAction,
  },
  getters: {
  
  }
}
