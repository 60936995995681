<template>
  <k-container class="view-media-container">
    <template #header>
      <h2>媒體庫</h2>
      <k-button theme="primary" @click="showModal">上傳檔案</k-button>
    </template>
    <section class="media-widgets">
      <k-widget-icon 
        @click="toFolder('image')"
        icon="image" theme="warning"
        :header="filterByMediaType.image.length"
        text="IMAGE" />
      <k-widget-icon 
        @click="toFolder('video')"
        icon="play-btn-fill"
        theme="info"
        :header="filterByMediaType.video.length"
        text="VIDEO" />
      <k-widget-icon
        @click="toFolder('unused')"
        icon="file-earmark-excel"
        theme="danger"
        :header="filterByMediaType.unused.length"
        text="Not in Used" />
    </section>

    <section>
      <k-card>
        <h3 slot="header">Recent File</h3>

        <k-table :items="recentFile" :fields="fields" borderless hover>
          <template #cell(file_name)="data">
            <div :class="`media-type ${data.item.file_type}`">
              <k-icon :icon="data.item.file_type.split('/')[0]==='image' ? 'image' : 'play-btn-fill'"></k-icon>
            </div>
            <span class="file-name" style="cursor: pointer" @click="changeFocusFile(data.item)">{{ data.value }}</span>
          </template>

          <template #cell(file_path)="data">
            {{ data.value | parseFilePath }}
          </template>

          <template #cell(useIn)="data">
              <k-tag theme="danger" v-if="!data.value">未被使用</k-tag>
              <k-tag theme="secondary" v-else>
                <k-icon :icon="typeIcon[data.value]"></k-icon>
                {{ data.value }}
              </k-tag>
          </template>

          <template #cell(file_uploadAt)="data">
            {{ data.value.format('YYYY/MM/DD hh:mm:ss') }}
          </template>

          <template #cell(action)="data">
            <k-dropdown>
                <div><k-icon icon="three-dots"></k-icon></div>
                <k-menu slot="overlay">
                  <k-menu-item
                    key="1"
                    v-copy="data.item.file_path"
                    v-copy:success="onCopy">
                    <k-icon icon="link" theme="info" />複製網址
                  </k-menu-item>
                  <k-menu-item key="2"><k-icon icon="trash" theme="danger" />刪除</k-menu-item>
                </k-menu>
              </k-dropdown>
          </template>
        </k-table>
      </k-card>
    </section>

    <MediaDrawer :focusFile="focusFile" />

    <k-modal :show.sync="modalIsShow" title="Upload File" hideFooter @hide="resetForm">
      <k-form :questionConfig="questions" @submit="handleSubmit"></k-form>
    </k-modal>
  </k-container>
</template>

<script>
import KTable from '@/components/Table'
import KCard from '@/components/Card'
import KDropdown from '@/components/Dropdown'
import KButton from '@/components/Button'
import KModal from '@/components/Modal'
import { KForm } from '@/components/Form'
import { KMenu, KMenuItem } from '@/components/Menu'
import { KWidgetIcon } from '@/components/Widget'
import KTag from '@/components/Tag'
import MediaDrawer from './components/drawer'
import storage from '@/utils/storage'
import { mapActions, mapGetters } from 'vuex'

const fields = [
  { key: 'file_name', fieldIndex: 'file_name', label: '名稱', sorter: (a, b) => ('' + a).localeCompare(b), searchable: true },
  { key: 'file_path', fieldIndex: 'file_path', label: '資料夾'},
  { key: 'useIn', fieldIndex: 'useIn', label: '使用於'},
  { key: 'file_uploadAt', fieldIndex: 'file_uploadAt', label: '上傳時間', sorter: (a, b) => a.unix() - b.unix() }, 
  { key: 'action', fieldIndex: 'action', label: '' }, 
]

export default {
  name: 'Media',
  mounted() {
    this.$store.dispatch('media/query')
  },
  data() {
    return {
      fields,
      questions: genQuestions(this.handleFileChange),
      focusFile: {},
      typeIcon: {
        'Category':  'diagram-3',
        'Post': 'file-earmark-text-fill',
        'Product': 'cart-fill'
      },
      showDrawer: false,
      modalIsShow: false,
    }
  },
  methods: {
    ...mapActions('media', ['insertFileInfo']),

    changeFocusFile(item) {
      this.focusFile = { ...item }
    },
    toFolder(type) {
      this.$router.push(`/media/${type}`)
    },
    onCopy() {
      this.$message.success('以複製圖片網址至剪貼簿')
    },
    showModal() {
      this.modalIsShow = true;
    },
    handleFileChange(info) {
      this.questions[1].imgSrc = info.src
    },
    async handleSubmit({ fileList, values }) {
      const { folder } = values
      const { file } = fileList
      const response = await storage.path(`img${folder !== '/self' ? folder: ''}`).put(file[0])
      await this.insertFileInfo({ ...response })
      console.log(response)
      switch (response.status) {
        case 200:
          this.modalIsShow = false
          return
        case 409:
          this.$message.error('此檔案已存在於目標資料夾')
          return
        default: return
      }
    },
    resetForm() {
      this.questions.forEach(q => { if(q.value) delete q.value });
    }

  },
  computed: {
    ...mapGetters('media', ['recentFile', 'filterByMediaType'])
  },
  filters: {
    parseFilePath: path => {
      const pathArray = path.split('/')
      return pathArray.slice(1, pathArray.length-1).join('/')
    }
  },
  components: {
    KTable, KCard, KWidgetIcon, KDropdown, KMenu, KMenuItem, MediaDrawer, KButton, KModal, KForm, KTag
  }
}

const genQuestions = uploadCallback => [
  {
          questionType: 'k-select',
          name: 'folder',
          label:"上傳資料夾",
          options: [
            { value: '/category', text: '/category' },
            { value: '/post', text: '/post' },
            { value: '/product', text: '/product' },
            { value: '/self', text: '/self' },
          ],
          required: true,
          theme: 'light',
          placeholder: '選擇要上傳至哪一個資料夾'
        },
        {
          questionType: 'k-upload',
          name: 'file', 
          value: null,
          imgSrc: null,
          alt: 'banner',
          label: '選擇檔案',
          required: true,
          block: true,
          onChange: info => uploadCallback(info)
        },
        {
          questionType: 'k-btn',
          label: "submit",
          theme: "primary",
          type: "submit"
        }
]
</script>
